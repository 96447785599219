import { InferType, object, string } from "yup";
import { useForm } from "react-hook-form";
import { Input, Textarea } from "@nextui-org/input";
import { Button } from "@nextui-org/button";
import CustomRegister from "@utils/functions/custom-register.function";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const ContactSchema = object().shape({
  firstName: string().required("required"),
  lastName: string().required("required"),
  email: string().required("required").email("email"),
  message: string().required("required"),
});

export type ContactSchema = InferType<typeof ContactSchema>;

const ContactForm = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useForm<ContactSchema>({ resolver: yupResolver(ContactSchema), mode: "all" });
  const customRegister = CustomRegister(register, errors);

  return (
    <form
      className="my-10 flex flex-col gap-5"
      action="https://formsubmit.co/barbaraschluderbach@gmail.com"
      method="POST"
    >
      <div className="flex flex-row gap-5">
        <Input {...customRegister("firstName")} name="firstname" type="string" label={t("firstname")} />
        <Input {...customRegister("lastName")} name="lastname" type="string" label={t("lastname")} />
      </div>
      <Input {...customRegister("email")} name="email" type="email" label={t("email")} />
      <Textarea {...customRegister("message")} name="message" label={t("message")} minRows={10} className="w-full" />
      <Button type="submit" color={"secondary"}>
        {t("send")}
      </Button>
    </form>
  );
};

export default ContactForm;
