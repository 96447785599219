import translationFR from "./fr/translations.json";
import translationEN from "./en/translations.json";
import translationDE from "./de/translations.json";
import translationIT from "./it/translations.json";

export enum Locales {
  FR = "fr",
  EN = "en",
  DE = "de",
  IT = "it",
}

export const translations = {
  [Locales.FR]: {
    translation: translationFR,
  },
  [Locales.EN]: {
    translation: translationEN,
  },
  [Locales.DE]: {
    translation: translationDE,
  },
  [Locales.IT]: {
    translation: translationIT,
  },
};
