import { Image } from "@nextui-org/react";
import { Pages } from "@utils/router/router.utils";
import { getStrapiImage } from "@utils/strapi.utils";
import { useTranslation } from "react-i18next";
import { useGetHome } from "src/services/services";
import Seo from "@components/Seo";
import { useMemo } from "react";

const ImageContainer = ({ img, title, to }: { img: string; title: string; to: string }) => (
  <div className="group relative h-[300px] w-full cursor-pointer">
    <a href={to}>
      <Image
        removeWrapper
        className="object-cover w-0 h-0 min-w-full min-h-full duration-1000 ease-in group-hover:brightness-50"
        src={img}
        alt={title}
        radius="none"
      />
      <div className="absolute bottom-[5%] z-50 w-full">
        <p className="px-20 py-4 text-lg text-center text-white duration-1000 ease-in bg-black bg-opacity-30 group-hover:bg-opacity-90">
          {title}
        </p>
      </div>
    </a>
  </div>
);

const Home = () => {
  const { t, i18n } = useTranslation();
  const { data } = useGetHome(i18n.language);

  const cleanDescription = useMemo(() => {
    if (!data?.main_title) return "";
    return data.main_title.length > 155 ? data.main_title.substring(0, 155) + "..." : data.main_title;
  }, [data?.main_title]);

  if (!data) return null;

  return (
    <>
      <Seo
        title={t("home.title")}
        description={cleanDescription}
        type="WebSite"
        image={getStrapiImage(data.main_img)}
        keywordsKey="common"
        author="Barbara Schluderbacher"
        schemaMarkup={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "Barbara Schluderbacher - Médecine Symbolique",
          description: cleanDescription,
          url: window.location.href,
          publisher: {
            "@type": "Person",
            name: "Barbara Schluderbacher",
            image: getStrapiImage(data.main_img),
          },
          about: {
            "@type": "Thing",
            name: "Médecine Symbolique, Harmonisation Énergétique, Communication Consciente, Ennéagramme",
            description:
              "Services de thérapie incluant la médecine symbolique, l'harmonisation énergétique, la communication consciente et l'ennéagramme",
          },
          offers: {
            "@type": "AggregateOffer",
            name: "Services Thérapeutiques",
            description: "Ensemble complet de services thérapeutiques",
            itemListElement: [
              {
                "@type": "Offer",
                itemOffered: {
                  "@type": "Service",
                  name: "Médecine Symbolique",
                  url: `${window.location.origin}/medecine-symbolique`,
                },
              },
              {
                "@type": "Offer",
                itemOffered: {
                  "@type": "Service",
                  name: "Harmonisation Énergétique",
                  url: `${window.location.origin}/harmonisation`,
                },
              },
              {
                "@type": "Offer",
                itemOffered: {
                  "@type": "Service",
                  name: "Communication Consciente",
                  url: `${window.location.origin}/communication-consciente`,
                },
              },
              {
                "@type": "Offer",
                itemOffered: {
                  "@type": "Service",
                  name: "Ennéagramme",
                  url: `${window.location.origin}/enneagramme`,
                },
              },
            ],
          },
        }}
      />
      <main>
        <div className="flex flex-col gap-3">
          <section className="relative top-0 h-[100vh] md:h-[80vh]">
            <Image
              removeWrapper
              className="object-cover w-0 h-0 min-w-full min-h-full brightness-50"
              src={getStrapiImage(data.main_img)}
              alt={data.main_title}
              radius="none"
            />
            <div className="absolute bottom-[5%] z-50 flex w-full flex-col items-center text-white md:bottom-[10%]">
              <h1 className="p-5 mb-20 text-xl uppercase border-white border-b-1 border-t-1 md:p-10 md:text-5xl">
                {data.main_title}
              </h1>
            </div>
            <div className="absolute bottom-[5%] z-50 flex w-full flex-col items-center text-white">
              <a
                href={Pages.HARMONISATION}
                className="text-xl text-white no-underline cursor-pointer md:text-2xl"
                aria-label={t("home.seeMore")}
              >
                {t("home.seeMore")}
              </a>
            </div>
          </section>
          <section className="flex flex-col w-full gap-3 md:flex-row" aria-label={t("home.services")}>
            <ImageContainer img={getStrapiImage(data.left_img)} title={data.left_title} to={Pages.SYMBOLIC_MEDICINE} />
            <ImageContainer
              img={getStrapiImage(data.center_img)}
              title={data.center_title}
              to={Pages.MINDFULL_COMMUNICATION}
            />
            <ImageContainer img={getStrapiImage(data.right_img)} title={data.right_title} to={Pages.CONSTELLATION} />
          </section>
        </div>
      </main>
    </>
  );
};

export default Home;
