import { Image } from "@nextui-org/image";
import { getStrapiImage } from "@utils/strapi.utils";
import { useTranslation } from "react-i18next";
import { useGetAbout } from "src/services/services";
import Seo from "@components/Seo";
import { motion } from "framer-motion";
import { useMemo } from "react";

const About = () => {
  const { t, i18n } = useTranslation();
  const { data } = useGetAbout(i18n.language);

  const cleanDescription = useMemo(() => {
    if (!data?.description) return "";
    const strippedHtml = data.description.replace(/<[^>]*>/g, "");
    return strippedHtml.length > 155 ? strippedHtml.substring(0, 155) + "..." : strippedHtml;
  }, [data?.description]);

  if (!data) return null;

  return (
    <>
      <Seo
        title={t("about.title")}
        description={cleanDescription}
        type="Person"
        image={getStrapiImage(data.photo)}
        keywordsKey="about"
        author="Barbara Schluderbacher"
        schemaMarkup={{
          "@context": "https://schema.org",
          "@type": "Person",
          name: "Barbara Schluderbacher",
          url: window.location.href,
          image: getStrapiImage(data.photo),
          description: cleanDescription,
          jobTitle: "Thérapeute holistique",
          worksFor: {
            "@type": "Organization",
            name: "Cabinet Barbara Schluderbacher",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Rue de la Servette 67",
              addressLocality: "Genève",
              postalCode: "1202",
              addressCountry: "CH",
            },
          },
          knowsAbout: [
            "Médecine symbolique",
            "Communication consciente",
            "Harmonisation",
            "Ennéagramme",
            "Thérapie holistique",
          ],
        }}
      />
      <div className="px-4 mx-auto max-w-7xl">
        <motion.article
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex flex-col gap-8 mt-24 mb-12 md:mt-40 lg:flex-row lg:items-start lg:gap-16"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="lg:sticky lg:top-32 lg:w-1/3"
          >
            <div className="relative overflow-hidden shadow-xl rounded-2xl">
              <Image
                width={700}
                height={300}
                src={getStrapiImage(data.photo)}
                alt={t("about.photoAlt") || "Barbara Schluderbacher - Holistic Therapist"}
                className="w-full h-full object-fit"
              />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="lg:w-2/3"
          >
            <div
              className="prose prose-lg max-w-none dark:prose-invert prose-headings:font-display prose-p:leading-relaxed prose-img:rounded-xl prose-img:shadow-lg"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </motion.div>
        </motion.article>
      </div>
    </>
  );
};

export default About;
