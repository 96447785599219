import { createBrowserRouter } from "react-router-dom";
import { RouterError } from "@utils/router/RouterError";
import { Layout } from "@screens/layout/Layout";
import Home from "@screens/home/Home";
import { Pages } from "./router.utils";
import HarmonisationPage from "@screens/harmonisation/Harmonisation";
import About from "@screens/about/About";
import Contact from "@screens/contact/Contact";
import Agenda from "@screens/agenda/Agenda";
import Tarifs from "@screens/tarifs/Tarifs";
import SymbolicMedicine from "@screens/symbolic_medicine/SymbolicMedicine";
import MindfullCommunication from "@screens/mindfull_communication/MindfullCommunication";
import Constellation from "@screens/constellation/Constellation";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <RouterError />,
    children: [
      {
        path: Pages.HOME,
        element: <Home />,
      },
      {
        path: Pages.HARMONISATION,
        element: <HarmonisationPage />,
      },
      {
        path: Pages.SYMBOLIC_MEDICINE,
        element: <SymbolicMedicine />,
      },
      {
        path: Pages.MINDFULL_COMMUNICATION,
        element: <MindfullCommunication />,
      },
      {
        path: Pages.CONSTELLATION,
        element: <Constellation />,
      },
      {
        path: Pages.AGENDA,
        element: <Agenda />,
      },
      {
        path: Pages.PRICES,
        element: <Tarifs />,
      },
      {
        path: Pages.ABOUT,
        element: <About />,
      },
      {
        path: Pages.CONTACT,
        element: <Contact />,
      },
    ],
  },
]);

export default router;
