import { Outlet } from "react-router-dom";
import { Topbar } from "./Topbar";
import Footer from "./Footer";

export const Layout = () => {
  return (
    <div className="flex min-h-[100dvh] flex-col overflow-hidden">
      <div className="flex-1">
        <Topbar />
        <Outlet />
      </div>
      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  );
};
