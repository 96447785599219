import I18nUtils from "@assets/locales/i18n.utils";

const { t } = I18nUtils;

export const ErrorEnum = {
  TOKEN_NOT_VALID: "TOKEN_NOT_VALID",
  TRY_AGAIN: t("errors:tryAgain"),
  NETWORK_ERROR: t("errors:networkError"),
  WRONG_CREDENTIALS: t("errors:wrongCredentials"),
  EMAIL_ALREADY_EXISTS: t("errors:emailAlreadyUsed"),
};

export const ERRORS = {
  [ErrorEnum.TOKEN_NOT_VALID]: t("errors:invalidToken"),
  [ErrorEnum.TRY_AGAIN]: t("errors:tryAgain"),
  [ErrorEnum.NETWORK_ERROR]: t("errors:networkError"),
  [ErrorEnum.WRONG_CREDENTIALS]: t("errors:wrongCredentials"),
  [ErrorEnum.EMAIL_ALREADY_EXISTS]: t("errors:emailAlreadyUsed"),
};
