import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "@utils/router/router.routes";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "sonner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Toaster position={"bottom-center"} richColors toastOptions={{ duration: 1500 }} />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}

export default App;
