import { Image } from "@nextui-org/image";
import { Divider } from "@nextui-org/divider";
import ContactForm from "./components/ContactForm";
import Map from "./components/Map.component";
import { useTranslation } from "react-i18next";
import Seo from "@components/Seo";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("contact.title")}
        description={t("contact.description")}
        type="Organization"
        keywordsKey="contact"
        author="Barbara Schluderbacher"
        schemaMarkup={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Cabinet Barbara Schluderbacher",
          description: t("contact.description"),
          url: window.location.href,
          address: {
            "@type": "PostalAddress",
            streetAddress: "Rue de la Servette 67",
            addressLocality: "Genève",
            postalCode: "1202",
            addressCountry: "CH",
          },
          telephone: "+41 76 615 89 89",
          email: "contact@barbaraschluderbacher.com",
          openingHours: ["Mo-Fr 09:00-18:00", "Sa 09:00-12:00"],
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+41 76 615 89 89",
            contactType: "customer service",
            availableLanguage: ["French", "English", "German"],
          },
        }}
      />
      <header className="h-[30dvh] overflow-hidden">
        <Image
          removeWrapper
          radius="none"
          src="/contact.png"
          alt={t("title")}
          className="object-cover w-0 h-0 min-w-full min-h-full brightness-50"
        />
      </header>
      <main className="flex flex-col-reverse justify-center gap-10 mt-10 sm:flex-row">
        <section className="flex flex-col gap-3" aria-label="Contact Information">
          <Map />
          <div className="flex flex-row justify-center gap-2">
            <Divider orientation="vertical" />
            <address className="not-italic">
              <p>Barbara Schluderbacher</p>
              <p>
                <a href="tel:+33652353153" className="hover:text-primary">
                  +33 6 52 35 31 53
                </a>
              </p>
              <p>7 rue Sergent J Revel</p>
              <p>73000, Jacob-Bellecombette</p>
            </address>
          </div>
        </section>
        <section className="flex flex-col gap-3 px-10" aria-label="Contact Form">
          <h1 className="text-5xl font-semibold text-primary">{t("contact.title")}</h1>
          <h2 className="text-xl font-semibold text-secondary">{t("contact.description")}</h2>
          <ContactForm />
        </section>
      </main>
    </>
  );
};

export default Contact;
