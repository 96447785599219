import { ERRORS } from "@constants/errors.constants.ts";
import { toast } from "sonner";

export const HandleRequestError = (err: any) => {
  if (err?.message && ERRORS[err?.message]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    // enqueueSnackbar(ERRORS[err?.message], {
    //   variant: 'error'
    // });
  } else if (err) {
    toast.error(err.message);
  }
  throw err?.response?.data;
};
