import { Image } from "@nextui-org/image";
import { Divider } from "@nextui-org/divider";
import { Carousel, CarouselContent, CarouselItem, type CarouselApi } from "./ui/carousel";

import { useCallback, useState } from "react";
import { Dot } from "lucide-react";
import { COLORS } from "@constants/colors.constant";
import { getStrapiImage } from "@utils/strapi.utils";

export interface BasePageProps {
  title: string;
  subtitle: string;
  backgroundImg: string;
  imageCarousel: { data: any[] };
  contentStart: string;
  contentEnd?: string;
}

const BasePage = (props: BasePageProps) => {
  const { title, subtitle, backgroundImg, imageCarousel, contentStart, contentEnd } = props;
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  const scrollTo = useCallback(
    (index: number) => {
      if (api) {
        setCurrent(index);
        return api.scrollTo(index);
      }
    },
    [api]
  );

  return (
    <article className="flex flex-col">
      <header className="h-[80vh] overflow-hidden relative">
        <Image
          removeWrapper
          radius="none"
          src={getStrapiImage(backgroundImg)}
          alt={title}
          className="object-cover w-0 h-0 min-w-full min-h-full brightness-50"
          loading="eager"
        />
      </header>
      <main className="relative z-30 md:top-[-250px]">
        <section className="bg-white px-5 pt-12 md:mx-[20%] md:px-32">
          <h1 className="mb-2 text-5xl text-center text-primary md:text-6xl md:tracking-widest">{title}</h1>
          <Divider />
          <h2 className="mt-2 text-2xl tracking-widest text-center text-secondary">{subtitle}</h2>
          <div
            className="mt-10 prose prose-lg dark:prose-invert max-w-none"
            dangerouslySetInnerHTML={{ __html: contentStart }}
          />
        </section>
        <section className="mt-10" aria-label="Image Gallery">
          <Carousel setApi={setApi} className="w-full" aria-label="Service images">
            <CarouselContent>
              {imageCarousel.data?.map((media, i) => (
                <CarouselItem className="w-full h-72" key={i}>
                  <Image
                    removeWrapper
                    radius="none"
                    src={media.attributes.url}
                    alt={`${title} - Image ${i + 1}`}
                    className="object-cover w-0 h-0 min-w-full min-h-full brightness-50"
                    loading="lazy"
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
          <nav className="flex flex-row justify-center" aria-label="Gallery navigation">
            {imageCarousel.data?.map((_, index) => (
              <button
                key={index}
                onClick={() => scrollTo(index)}
                className="focus:outline-none focus:ring-2 focus:ring-primary"
                aria-label={`Go to image ${index + 1}`}
                aria-current={current === index}
              >
                <Dot color={current === index ? COLORS.secondary : COLORS.primary} size={60} />
              </button>
            ))}
          </nav>
        </section>
        {contentEnd && (
          <section className="px-10 md:mx-[20%] md:px-32">
            <div
              className="mt-10 prose prose-lg dark:prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: contentEnd }}
            />
          </section>
        )}
      </main>
    </article>
  );
};

export default BasePage;
