import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const Map = () => {
  return (
    <div className="relative h-[400px] w-[600px] overflow-hidden rounded-sm">
      <MapContainer
        style={{
          height: "400px",
          width: "100%",
        }}
        center={[45.55864715576172, 5.9151177406311035]}
        zoom={15}
      >
        <TileLayer attribution="Google Maps" url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
        <Marker position={[45.55864715576172, 5.9151177406311035]} />
      </MapContainer>
    </div>
  );
};

export default Map;
