export enum Pages {
  HOME = "/",
  HARMONISATION = "/harmonisation",
  SYMBOLIC_MEDICINE = "/medecine-symbolique",
  MINDFULL_COMMUNICATION = "/communication-consciente",
  CONSTELLATION = "/constellation",
  AGENDA = "/agenda",
  PRICES = "/tarifs",
  ABOUT = "/about",
  CONTACT = "/contact",
}

export const PagesTranslation: Record<Pages, string> = {
  [Pages.HOME]: "home",
  [Pages.HARMONISATION]: "harmonisation",
  [Pages.SYMBOLIC_MEDICINE]: "symbolicMedicine",
  [Pages.MINDFULL_COMMUNICATION]: "mindfullCommunication",
  [Pages.CONSTELLATION]: "constellation",
  [Pages.AGENDA]: "agenda",
  [Pages.PRICES]: "prices",
  [Pages.ABOUT]: "about",
  [Pages.CONTACT]: "contact",
};

export function translatePage(page: Pages): string {
  return PagesTranslation[page] || "";
}

export function hasEnumValue(array: string[], enumType: Record<string, string>, value: string): boolean {
  return array.some((enumValue) => enumType[enumValue] === value);
}
