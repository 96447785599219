import { Card, CardBody } from "@nextui-org/card";
import { Divider } from "@nextui-org/divider";
import { useDisclosure } from "@nextui-org/modal";
import { useState } from "react";
import AgendaModal, { AgendaModalProps } from "./components/Agenda.modal";
import { useTranslation } from "react-i18next";
import { useGetAgenda } from "src/services/services";
import { getStrapiImage } from "@utils/strapi.utils";
import { motion } from "framer-motion";
import { Calendar, Clock, MapPin } from "lucide-react";
import Seo from "@components/Seo";

export interface AgendaCardProps {
  image: string;
  title: string;
  description: string;
  date: string;
  content: string;
  location?: string;
  time?: string;
  updatedAt?: Date;
}

interface AgendaCardComponentProps extends AgendaCardProps {
  onOpenModal: (title: string, content: string) => void;
}

const AgendaCard = (props: AgendaCardComponentProps) => {
  const { image, title, description, content, date, location, time, onOpenModal } = props;

  return (
    <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }} className="w-full">
      <Card
        onPress={() => onOpenModal(title, content)}
        isPressable
        className="overflow-hidden border-1 border-neutral-200 shadow-sm hover:shadow-lg transition-shadow duration-300 w-full md:h-[200px]"
      >
        <div className="flex flex-col h-full md:flex-row">
          <div className="w-full md:w-[300px] h-[200px] md:h-full relative">
            <img alt={title} src={getStrapiImage(image)} className="object-cover w-full h-full" />
            <div className="absolute px-4 py-2 text-white rounded-lg top-4 left-4 bg-secondary/90 backdrop-blur-sm">
              {date}
            </div>
          </div>

          <CardBody className="flex flex-col justify-between flex-1 p-4 md:p-6">
            <div>
              <div className="flex flex-col justify-between gap-2 mb-2 md:flex-row md:items-start">
                <h3 className="text-lg font-medium md:text-xl text-primary">{title}</h3>
                <div className="flex items-center gap-2 text-sm text-gray-500 shrink-0">
                  <Calendar size={16} className="text-secondary" />
                  <span>{date}</span>
                </div>
              </div>
              <Divider className="w-16 mb-3 bg-secondary/30" />
              <p className="text-sm text-gray-600 line-clamp-2">{description}</p>

              <div className="flex flex-wrap gap-4 mt-3 text-sm text-gray-500">
                {time && (
                  <div className="flex items-center gap-2">
                    <Clock size={14} className="text-secondary" />
                    <span>{time}</span>
                  </div>
                )}
                {location && (
                  <div className="flex items-center gap-2">
                    <MapPin size={14} className="text-secondary" />
                    <span className="line-clamp-1">{location}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end mt-4 md:mt-0">
              <button className="flex items-center gap-1 text-sm font-medium text-secondary hover:text-secondary/80">
                En savoir plus
                <span className="text-lg">→</span>
              </button>
            </div>
          </CardBody>
        </div>
      </Card>
    </motion.div>
  );
};

interface ApiAgendaItem {
  id: number;
  attributes: AgendaCardProps;
}

const Agenda = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [modalContent, setModalContent] = useState<Pick<AgendaModalProps, "title" | "content">>();
  const { t, i18n } = useTranslation();
  const { data } = useGetAgenda(i18n.language);

  if (!data) return null;

  const handleOpenModal = (title: string, content: string) => {
    setModalContent({ title, content });
    onOpen();
  };

  const events = (data?.data as ApiAgendaItem[]).sort((a, b) => {
    const dateA = new Date(a.attributes.updatedAt ?? "");
    const dateB = new Date(b.attributes.updatedAt ?? "");
    return dateA.getTime() - dateB.getTime();
  });

  const generateEventSchema = (event: AgendaCardProps) => ({
    "@type": "Event",
    name: event.title,
    description: event.description,
    image: getStrapiImage(event.image),
    startDate: event.date,
    ...(event.time && { doorTime: event.time }),
    ...(event.location && {
      location: {
        "@type": "Place",
        name: event.location,
        address: {
          "@type": "PostalAddress",
          addressLocality: event.location.split(",")[0].trim(),
        },
      },
    }),
    organizer: {
      "@type": "Person",
      name: "Barbara Schluderbacher",
      url: "https://barbara-schluderbacher.com",
    },
    eventStatus: "https://schema.org/EventScheduled",
    eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
  });

  return (
    <>
      <Seo
        title={t("agenda.title")}
        description={t("agenda.subtitle")}
        type="WebPage"
        keywordsKey="agenda"
        schemaMarkup={{
          "@context": "https://schema.org",
          "@type": "EventSeries",
          name: t("agenda.title") || "Agenda",
          description: t("agenda.subtitle"),
          subEvents: events.map((event) => generateEventSchema("attributes" in event ? event.attributes : event)),
        }}
      />
      <main className="px-4 mt-8 mb-12 md:mt-16 md:mb-20 md:px-10 lg:px-20">
        <header className="pt-8 mb-6 text-center md:pt-16 md:mb-8">
          <h1 className="mb-2 text-3xl tracking-widest md:text-4xl lg:text-5xl text-primary">{t("agenda.title")}</h1>
          <Divider className="w-24 mx-auto my-3 md:w-32 bg-secondary/30" />
          <p className="max-w-2xl px-4 mx-auto text-sm text-gray-600 md:text-base">{t("agenda.subtitle")}</p>
        </header>

        <section className="flex flex-col max-w-6xl gap-3 mx-auto md:gap-4" aria-label="Liste des événements">
          {events.map((event, i) => (
            <AgendaCard key={i} {...("attributes" in event ? event.attributes : event)} onOpenModal={handleOpenModal} />
          ))}
        </section>

        <AgendaModal isOpen={isOpen} onOpenChange={onOpenChange} {...modalContent} />
      </main>
    </>
  );
};

export default Agenda;
