import { useTranslation } from "react-i18next";
import BasePage from "@components/BasePage.component";
import { useGetMedicine } from "src/services/services";
import Seo from "@components/Seo";
import { useMemo } from "react";
import { getStrapiImage } from "@utils/strapi.utils";

const SymbolicMedicine = () => {
  const { t, i18n } = useTranslation();
  const { data } = useGetMedicine(i18n.language);

  const cleanDescription = useMemo(() => {
    if (!data?.contentStart) return "";
    const strippedHtml = data.contentStart.replace(/<[^>]*>/g, "");
    return strippedHtml.length > 155 ? strippedHtml.substring(0, 155) + "..." : strippedHtml;
  }, [data?.contentStart]);

  if (!data) return null;

  return (
    <>
      <Seo
        title={t("symbolicMedicine.title")}
        description={cleanDescription}
        type="Service"
        image={getStrapiImage(data.backgroundImg)}
        keywordsKey="symbolicMedicine"
        author="Barbara Schluderbacher"
        schemaMarkup={{
          "@context": "https://schema.org",
          "@type": "HealthAndBeautyBusiness",
          name: "Médecine Symbolique - Barbara Schluderbacher",
          image: getStrapiImage(data.backgroundImg),
          description: cleanDescription,
          url: window.location.href,
          hasOfferCatalog: {
            "@type": "OfferCatalog",
            name: "Services de Médecine Symbolique",
            itemListElement: [
              {
                "@type": "Offer",
                itemOffered: {
                  "@type": "Service",
                  name: "Consultation de Médecine Symbolique",
                  description: "Séance individuelle de médecine symbolique",
                },
              },
            ],
          },
        }}
      />
      <BasePage {...data} />
    </>
  );
};

export default SymbolicMedicine;
