import Flag from "@components/Flag.component";
import { Navbar, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle } from "@nextui-org/react";
import { Pages, translatePage } from "@utils/router/router.utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const pagesWithBg = [Pages.ABOUT, Pages.AGENDA, Pages.PRICES];

export const Topbar = () => {
  const [isTransparentBg, setIsTransparentBg] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (pagesWithBg.includes(pathname.replace(`/${i18n.language}`, "") as Pages)) return;
      setIsTransparentBg(window.scrollY <= 50);
    };

    setIsTransparentBg(!pagesWithBg.includes(pathname.replace(`/${i18n.language}`, "") as Pages));
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [pathname, i18n.language]);

  const isPageActive = (page: Pages) => {
    return pathname.includes(page);
  };

  const navLinkStyle = "text-white no-underline";

  return (
    <>
      <Navbar
        onMenuOpenChange={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
        isBlurred={false}
        maxWidth="full"
        className={`fixed z-[99999] text-white duration-1000 ease-in-out ${
          isTransparentBg ? "bg-transparent" : "bg-gray-800 text-white"
        }`}
      >
        <NavbarContent justify="start">
          <NavbarItem onClick={() => setIsMenuOpen(!isMenuOpen)} className="flex flex-row items-center gap-3 sm:hidden">
            <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} className="sm:hidden" />
            <span>{t("global.menu")}</span>
          </NavbarItem>
          <NavbarItem className="hidden sm:flex" isActive={isPageActive(Pages.HOME)}>
            <a className={navLinkStyle} href={Pages.HOME}>
              {t("pages.home")}
            </a>
          </NavbarItem>
        </NavbarContent>
        <NavbarContent className="hidden justify-around gap-12 sm:flex" justify="center">
          {Object.values(Pages)
            .filter((page) => page !== Pages.HOME)
            .map((page, index) => (
              <NavbarItem key={`${page}-${index}`} isActive={isPageActive(page)}>
                <a className={navLinkStyle} href={page}>
                  {t(`pages.${translatePage(page)}`)}
                </a>
              </NavbarItem>
            ))}
        </NavbarContent>
        <NavbarContent justify="end">
          <NavbarItem>
            <Flag />
          </NavbarItem>
        </NavbarContent>
        <NavbarMenu className="z-[999] gap-10 bg-white w-full">
          {Object.values(Pages).map((page, index) => (
            <NavbarMenuItem key={`${page}-${index}`} onClick={() => setIsMenuOpen(false)}>
              <a className="text-2xl text-black no-underline" href={page}>
                {t(`pages.${translatePage(page)}`)}
              </a>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
    </>
  );
};
