export function getStrapiURL(path = "") {
  return `${import.meta.env.VITE_STRAPI_URL || "http://localhost:1337"}${path}`;
}

export function getStrapiMedia(url: string | null): string {
  if (url == null) {
    return "";
  }
  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith("http") || url.startsWith("//")) {
    return url;
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${getStrapiURL()}${url}`;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export function getStrapiImage(data: any): string {
  if (!data.data) return "";
  return data.data.attributes.url;
}
