import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface SeoProps {
  title: string;
  description: string;
  image?: string;
  type?: "WebSite" | "WebPage" | "Article" | "Person" | "Organization" | "Service";
  schemaMarkup?: Record<string, any>;
  noIndex?: boolean;
  keywords?: string[];
  keywordsKey?: keyof typeof import("../assets/locales/fr/translations.json")["keywords"];
  author?: string;
  publishedTime?: string;
  modifiedTime?: string;
}

const Seo = ({
  title,
  description,
  image,
  type = "WebPage",
  schemaMarkup,
  noIndex,
  keywords: customKeywords,
  keywordsKey,
  author = "Barbara Schluderbacher",
  publishedTime,
  modifiedTime,
}: SeoProps) => {
  const { i18n, t } = useTranslation("seo");
  const location = useLocation();
  const currentLang = i18n.language.toLowerCase();

  const baseUrl = import.meta.env.VITE_APP_URL || "https://barbara-schluderbacher.com";
  const canonical = `${baseUrl}${location.pathname}`;
  const imageUrl = image ? (image.startsWith("http") ? image : `${baseUrl}${image}`) : undefined;

  // Get translated keywords
  const translatedCommonKeywords = t("keywords.common", { returnObjects: true }) as string[];
  const translatedPageKeywords = keywordsKey ? (t(`keywords.${keywordsKey}`, { returnObjects: true }) as string[]) : [];
  const keywords = [...(customKeywords || []), ...translatedCommonKeywords, ...translatedPageKeywords];

  // Basic schema markup based on type
  const baseSchemaMarkup = {
    "@context": "https://schema.org",
    "@type": type,
    url: canonical,
    name: title,
    description,
    inLanguage: currentLang,
    ...(imageUrl && { image: imageUrl }),
    ...(author && {
      author: {
        "@type": "Person",
        name: author,
      },
    }),
    organizer: {
      "@type": "Person",
      name: "Barbara Schluderbacher",
      url: "https://barbaraschluderbacher.com",
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "7 rue sergent J Revel",
      addressLocality: "Jacob-Bellecombette",
      postalCode: "73000",
      addressCountry: "FR",
    },
    priceRange: "EUR",
    telephone: "+33 6 52 35 31 53",
    ...(publishedTime && { datePublished: publishedTime }),
    ...(modifiedTime && { dateModified: modifiedTime }),
  };

  return (
    <Helmet>
      <html lang={currentLang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords.join(", ")} />}
      {author && <meta name="author" content={author} />}

      {noIndex ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <>
          <meta name="robots" content="index, follow" />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        </>
      )}

      <link rel="canonical" href={canonical} />

      {/* Open Graph */}
      <meta property="og:site_name" content="Barbara Schluderbacher" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonical} />
      <meta property="og:type" content={type === "WebSite" ? "website" : "article"} />
      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:alt" content={title} />
        </>
      )}
      <meta property="og:locale" content={currentLang} />
      {publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {modifiedTime && <meta property="article:modified_time" content={modifiedTime} />}

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && (
        <>
          <meta name="twitter:image" content={imageUrl} />
          <meta name="twitter:image:alt" content={title} />
        </>
      )}

      {/* PWA tags */}
      <meta name="application-name" content="Barbara Schluderbacher" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Barbara Schluderbacher" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#ffffff" />

      {/* Structured Data */}
      <script type="application/ld+json">{JSON.stringify(schemaMarkup || baseSchemaMarkup)}</script>

      {/* Preconnect to required origins */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
    </Helmet>
  );
};

export default Seo;
