import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import fr from "./fr/translations.json";
import en from "./en/translations.json";
import de from "./de/translations.json";
import it from "./it/translations.json";
import { StorageEnum, getStringItem } from "@utils/storage";

export enum LocalEnum {
  fr = "fr",
  EN = "en",
  DE = "de",
  IT = "it",
}

const defaultLng = getStringItem(StorageEnum.I18N) || (LocalEnum.fr as string);
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: defaultLng, // localstorage -> i18nextLng: en_US
    fallbackLng: LocalEnum.fr,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      fr: { translation: fr },
      de: { translation: de },
      it: { translation: it },
      en: { translation: en },
    },
  });

export default i18n;
export const { t } = i18n;
