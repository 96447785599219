import { Axios } from "@api/Axios";
import { QueryKey, useQuery } from "@tanstack/react-query";
import { AboutProps, Home, StrapiResponse, StrapiResponseBasic } from "./models";
import { BasePageProps } from "@components/BasePage.component";
import { AgendaCardProps } from "@screens/agenda/Agenda";
import { TarifCardProps } from "@screens/tarifs/Tarifs";

export const keys: Record<string, QueryKey> = {
  getHomePage: ["getHomePage"],
  getHarmonisation: ["getHarmonisation"],
  getMedicine: ["getMedicine"],
  getCommunication: ["getCommunication"],
  getEnenagram: ["getEnenagram"],
  getAgenda: ["getAgenda"],
  getPrices: ["getPrices"],
  getAbout: ["getAbout"],
};

export function useGetHome(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<Home>>("homes", {
        params: { populate: "*", locale: locale },
      });
      return getStrapiData(resp.data);
    },
    queryKey: [...keys.getHomePage, locale],
  });
}
export function useGetHarmonisation(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<BasePageProps>>("harmonisations", {
        params: { populate: "*", locale: locale },
      });
      return getStrapiData(resp.data);
    },
    queryKey: [...keys.getHarmonisation, locale],
  });
}

export function useGetMedicine(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<BasePageProps>>("medecines", {
        params: { populate: "*", locale: locale },
      });
      return getStrapiData(resp.data);
    },
    queryKey: [...keys.getMedicine, locale],
  });
}

export function useGetMindfullCommunication(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<BasePageProps>>("communications", {
        params: { populate: "*", locale: locale },
      });
      return getStrapiData(resp.data);
    },
    queryKey: [...keys.getCommunication, locale],
  });
}

export function useGetEnneagram(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<BasePageProps>>("enneagrams", {
        params: { populate: "*", locale: locale },
      });
      return getStrapiData(resp.data);
    },
    queryKey: [...keys.getEnenagram, locale],
  });
}

export function useGetAbout(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<AboutProps>>("abouts", {
        params: { populate: "*", locale: locale },
      });
      return getStrapiData(resp.data);
    },
    queryKey: [...keys.getAbout, locale],
  });
}

export function useGetAgenda(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<AgendaCardProps>>("agendas", {
        params: { populate: "*", locale: locale },
      });
      return resp.data;
    },
    queryKey: [...keys.getAgenda, locale],
  });
}

export function useGetPrices(locale: string) {
  return useQuery({
    queryFn: async () => {
      const resp = await Axios.get<StrapiResponse<TarifCardProps>>("tarifs", {
        params: { populate: "*", locale: locale },
      });
      return resp.data;
    },
    queryKey: [...keys.getPrices, locale],
  });
}

export function getStrapiData<T>(x: StrapiResponse<T>) {
  return x.data[0].attributes;
}

export function getStrapiDataWithBasic<T>(x: StrapiResponseBasic<T>) {
  return x.data[0].attributes.basic;
}
