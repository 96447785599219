import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/dropdown";
import ItalyFlag from "@svgs/italyFlag.svg?react";
import FrenchFlag from "@svgs/frenchFlag.svg?react";
import GermanFlag from "@svgs/germanFlag.svg?react";
import EnglishFlag from "@svgs/englishFlag.svg?react";
import { ReactElement, useMemo, useState } from "react";
import { Locales } from "@assets/locales";
import { useTranslation } from "react-i18next";

const LanguagesFlag: Record<Locales, ReactElement> = {
  fr: <FrenchFlag />,
  it: <ItalyFlag />,
  de: <GermanFlag />,
  en: <EnglishFlag />,
};

const options: Array<{ key: Locales; icon: ReactElement; title: string }> = [
  {
    key: Locales.FR,
    icon: <FrenchFlag className="w-10 h-10" />,
    title: "Français",
  },
  {
    key: Locales.IT,
    icon: <ItalyFlag className="w-10 h-10" />,
    title: "Italian",
  },
  {
    key: Locales.DE,
    icon: <GermanFlag className="w-10 h-10" />,
    title: "Deutsch",
  },
  {
    key: Locales.EN,
    icon: <EnglishFlag className="w-10 h-10" />,
    title: "English",
  },
];

const Flag = () => {
  const { i18n } = useTranslation();
  const [selectedFlag, setSelectedFlag] = useState(i18n.language);

  function onSelectChange(e: any) {
    setSelectedFlag(e.currentKey);
    i18n.changeLanguage(e.currentKey);
  }

  const selectedLanguage = useMemo(() => {
    return LanguagesFlag[selectedFlag as Locales];
  }, [selectedFlag]);

  return (
    <Dropdown>
      <DropdownTrigger>
        <a className="mt-5 h-10 w-10 cursor-pointer">{selectedLanguage}</a>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Language selection"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedFlag}
        onSelectionChange={onSelectChange}
      >
        {options
          .filter((option) => option.key !== selectedFlag)
          .map((option) => (
            <DropdownItem className="m-2" key={option.key} startContent={option.icon}>
              {option.title}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default Flag;
