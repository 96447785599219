import axios, { AxiosRequestConfig } from "axios";
import { HandleRequestError } from "./handle-request-error";

const config: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_STRAPI_URL + "/api/",
};

export const Axios = axios.create(config);

//ADD ALL Error messages from back to ErrorsConstants
Axios.interceptors.response.use(undefined, (error) => {
  HandleRequestError(error);
  return Promise.reject(error);
});
