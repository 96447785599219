import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/modal";
import { Button } from "@nextui-org/button";
import { Pages } from "@utils/router/router.utils";

export interface AgendaModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
  title?: string;
  content?: string;
}

const AgendaModal = (props: AgendaModalProps) => {
  const { isOpen, onOpenChange, title, content } = props;

  return (
    <Modal
      size="5xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      classNames={{
        base: "h-[85vh] max-w-[85vw] bg-background/80 backdrop-blur-md",
        header: "px-8 py-6",
        body: "px-8",
        footer: "px-8 py-6",
        closeButton: "hover:bg-default-100",
      }}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="flex flex-col">
              <h2 className="text-3xl font-light tracking-tight">{title}</h2>
            </ModalHeader>
            <ModalBody
              className="text-lg leading-relaxed scroll-smooth"
              dangerouslySetInnerHTML={{ __html: content ?? "" }}
            />
            <ModalFooter>
              <Button
                as="a"
                href={Pages.CONTACT}
                color="default"
                variant="flat"
                radius="full"
                size="lg"
                className="px-8 font-light tracking-wide no-underline hover:bg-default-100"
              >
                Me contacter
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AgendaModal;
