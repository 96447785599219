import { Divider } from "@nextui-org/divider";

const Footer = () => {
  return (
    <>
      <div className="flex w-full flex-col justify-center gap-3">
        <Divider />
        <a
          href="https://www.linkedin.com/in/alexandre-florent-624a84211/"
          className="text-center no-underline text-black"
        >
          All right reserved © 2024 - Alexandre Florent
        </a>
      </div>
    </>
  );
};

export default Footer;
