// @ts-nocheck
import { ChangeHandler, RefCallBack, UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types/path";
import { FieldErrors } from "react-hook-form/dist/types/errors";

export type CustomRegisterType = {
  error: boolean;
  helperText: string | undefined;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  ref: RefCallBack;
  name: TFieldName;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  inputProps?: Record<string, unknown>;
};

export default function CustomRegister<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  register: UseFormRegister<TFieldValues>,
  errors: FieldErrors<TFieldValues>
): (name: TFieldName, noHelper?: boolean) => CustomRegisterType {
  // NO helper for select component -- not supported by MUI
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  return (name: TFieldName, noHelpers: boolean) => {
    return {
      ...register(name),
      ...(noHelpers ? {} : { helperText: errors[name]?.message ? errorComponents(name) : "", error: !!errors[name] }),
      inputProps: { className: errors[name] ? "error" : "" },
    };
  };
}
